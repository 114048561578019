'use strict';

import React from 'react';

class Footer extends React.Component{

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer>

        Footer

      </footer>
    );
  }

}

export default Footer;
